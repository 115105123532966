<template>

  <div>

    <Navbar />

    <section class="section">
      <div class="container">
            <Pricing :pricingDataMonth="pricingDataMonth" :pricingDataYear="pricingDataYear" />
      </div>
    </section>

    <Footer />

  </div>

</template>

<script>
import Navbar from "@/components/navbar0";
import Pricing from "@/components/pricing_min_sale";
import Footer from "@/components/footer";
import store from "@/store/store";


export default {
  name: "buy",

  data() {
    return {



      file:'',
      id: 1,
      checkUpload: {
        user_id: 1,
        file_type: 'pdf',
      },
      imgUrl:'',
      user: {
        user_id: 1,
        first_name: '',
        last_name: '',
        email: '',
        company: '',
      },
      showUrlImg: true,

      input2sql: {
        user_id: 1,
      },

      orders: [],
      expiration_date_milis: 0,

      total_balance: 0,

      add_cash_sql_in: {
        user_email: '',
        user_id: 0,
        service_name: 'Депозит Skillrank',
        description: 'Депозит Skillrank',
        price: 0,
        price_rub: 0,
        quantity: 1,
      },

      /* members: [
         {first_name:'igor',last_name:'gon'},
         {first_name:'anton',last_name:'ve'}
       ],*/

      account_statistics: {
        numberOfResumesInSearch: 0,
        numberOfResumesViewed: 0,
        numberOfResumesSaved: 0,
        numberOfEmailsSent: 0,
      },

      pricingDataMonth: [
        {
          title: "Пробный доступ",
          price: 0,
          quantity: 1,
          period: "мес",
          feature: [
            "",
            "5 писем рассылки",
            "Аналитика по рассылке",
          ],
          button: "Получить",
          isBest: true,
        },
        {
          title: "Полный Доcтуп",
          pastprice: 7990,
          price: 6000,
          quantity: 1,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
          ],
          button: "Купить",
          isBest: false,
        },
        {
          title: "Интеграция",
          pastprice: 9990,
          price: 8000,
          quantity: 1,
          period: "мес",
          feature: [
            "Полный доступ и интеграция с ATS",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
            "Интеграция с ATS или CRM"
          ],
          button: "Купить",
          isBest: false,
        },
      ],
      pricingDataYear: [
        {
          title: "Пробный доступ",
          price: 0,
          quantity: 12,
          period: "мес",
          feature: [
            "10 просмотров резюме",
            "5 писем рассылки",
            "Аналитика по рассылке",
          ],
          button: "Получить",
          isBest: true,
        },
        {
          title: "Полный Доcтуп",
          pastprice: 5990,
          price: 4800,
          quantity: 12,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
          ],
          button: "Купить",
          isBest: false,
        },
        {
          title: "Интеграция",
          pastprice: 7490,
          price: 5600,
          quantity: 12,
          period: "мес",
          feature: [
            "Полный доступ и интеграция с ATS",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
            "Интеграция с ATS или CRM"
          ],
          button: "Купить",
          isBest: false,
        },
      ],

      in_promo: {
        user_id:0,
        promo_code: '',
      },
    };
  },
  methods: {

    applyPromo() {
      this.in_promo.promo_code = this.$route.params.promo_code;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/promo/apply-promo-code?access_token=' + store.state.token,
        data: this.in_promo,
      }).then((res) => {
        console.log(res.data)
        if (res.data.promo_code_status) {
          this.$vs.notify({
            time: 4000,
            title: 'Успех',
            text: 'Промо код верный!',
            color: 'success'
          });
        } else {
          this.$vs.notify({
            time: 4000,
            title: 'Неверная промо ссылка',
            text: 'проверьте код и попробуйте другую ссылку',
            color: 'danger'
          });
          this.$router.push({path:'/'});
        }

      });

    },

  },
  mounted() {
    this.applyPromo();
  },
  components: {
    Navbar,
    Pricing,
    Footer,
  },

}
</script>

<style scoped>

</style>